<template>
  <ServiceViewPage :service="service" :subtitle="service.title">
    <v-form ref="form" :disabled="saving">
      <v-row v-if="!userHash">
        <v-col cols="12">
          <v-text-field
            v-model="form.name"
            :rules="[v => !!v || 'Укажите ФИО']"
            label="Фамилия Имя Отчество"
            name="name"
          />
        </v-col>
        <v-col cols="12" md="6">
          <PhoneField v-model="form.phone" type="phone" label="Телефон" name="phone" />
        </v-col>
        <v-col cols="12" md="6">
          <EmailField
            v-model="form.email"
            type="email"
            label="Электронная почта"
            name="email"
            required
          />
        </v-col>
      </v-row>
      <v-row v-if="service.type === ServiceType.RENT_OFFICE" dense>
        <v-col cols="12">
          <TreeSelect
            v-model="form.rentUnitId"
            :label="$t('Помещение')"
            :items="units"
            autocomplete
            :rules="[$rules.required()]"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <DynamicForm v-model="form.dynamicFields" :dynamic-fields="service.dynamicFields" />
        </v-col>
      </v-row>
    </v-form>

    <div class="mt-6">
      <v-btn v-if="showCatalog" color="t-grey" :disabled="saving" outlined @click="$emit('back')">
        {{ 'Назад' }}
      </v-btn>
      <v-btn
        v-if="service.addButton"
        class="ml-2"
        color="primary"
        :loading="saving"
        @click.prevent="onSubmit"
      >
        {{ service.buttonName }}
      </v-btn>
    </div>
    <div class="mt-4">
      <AcceptTerms :service="service" />
    </div>

    <v-snackbar v-model="snackbar">
      {{ $t('Заявка создана. В ближайшее время с вами свяжется наш менеджер.') }}
    </v-snackbar>
  </ServiceViewPage>
</template>

<script>
import { actions, getters, state } from '@/store/store';
import { ServiceType } from '@/constants/service';
import PhoneField from '@/components/ui/PhoneField.vue';
import EmailField from '@/components/ui/EmailField.vue';
import AcceptTerms from '@/components/terms/AcceptTerms.vue';
import DynamicForm from '@/components/dynamic-fields/DynamicForm.vue';
import ServiceViewPage from '@/views/services/views/ServiceViewPage.vue';
import TreeSelect from '@/components/ui/TreeSelect.vue';

const initForm = () => ({
  name: undefined,
  phone: undefined,
  email: undefined,
  rentUnitId: undefined,
  dynamicFields: null,
});

export default {
  name: 'SimpleServiceView',

  components: {
    TreeSelect,
    ServiceViewPage,
    DynamicForm,
    AcceptTerms,
    EmailField,
    PhoneField,
  },

  data: () => ({
    dialog: false,
    saving: false,
    snackbar: false,
    form: initForm(),
  }),

  computed: {
    ServiceType() {
      return ServiceType;
    },
    service() {
      return state.selectedService;
    },
    showCatalog() {
      return getters.showCatalog();
    },
    units() {
      return state.units;
    },
    userHash() {
      return state.userHash;
    },
  },

  mounted() {
    if (this.service.type === ServiceType.RENT_OFFICE && !this.units.length) {
      actions.fetchUnits();
    }
  },

  methods: {
    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      try {
        const payload = { ...this.form, source: 'widget', serviceId: this.service.id };
        const serviceTicket = await actions.createTicket(payload);
        await actions.handlePayment({ serviceTicket });
      } catch (e) {
        alert('Произошла ошибка :( Попробуйте повторить позже!');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
