<template>
  <div>
    <PageHeader :title="service.name" :subtitle="subtitle" />

    <v-row>
      <v-col cols="12" md="8">
        <BlockTitle class="mb-5">Описание и характеристики</BlockTitle>
        <EditorContent class="mt-4" :content="service.description" />
        <div class="mt-4 font-weight-medium">Расчетный час {{ service.checkoutTime }}</div>

        <v-form ref="form" :disabled="saving" @submit.prevent="onSubmit">
          <BlockTitle class="mt-5 mb-5">Новое бронирование</BlockTitle>

          <v-row>
            <v-col cols="12">
              <HotelSearchForm ref="searchForm" :service="service" @result="onSearchResult" />
            </v-col>
          </v-row>

          <template v-if="searchResult && searchResult.length > 0">
            <v-row>
              <v-col cols="12">
                <HotelCommonForm
                  ref="commonForm"
                  v-model="form"
                  :service="service"
                  :search-params="searchParams"
                  :search-result="searchResult"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="form.name"
                  :rules="[v => !!v || 'Укажите ФИО']"
                  label="Фамилия Имя Отчество"
                  name="name"
                />
              </v-col>
              <v-col cols="12" md="4">
                <PhoneField v-model="form.phone" label="Телефон" name="phone" type="phone" />
              </v-col>
              <v-col cols="12" md="8">
                <EmailField
                  v-model="form.email"
                  required
                  label="Электронная почта"
                  name="email"
                  type="email"
                />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12">
                <v-select
                  v-model="form.paymentType"
                  label="Способ оплаты"
                  :items="paymentTypeItems"
                />
              </v-col>
              <v-col v-if="form.paymentType === PaymentType.WIRE_PAYMENT" cols="12">
                <ExternalCompanyForm v-model="externalCompanyForm" />
              </v-col>
            </v-row>

            <div class="mt-6 d-flex flex-column gap-6">
              <div v-if="service.onlyForRFResidents">
                <v-checkbox
                  v-model="form.confirmRFResidents"
                  label="Я подтверждаю, что являюсь резидентом РФ"
                  :rules="[value => value || 'Заказ услуги доступен только для резидентов РФ']"
                  hide-details="auto"
                />
              </div>

              <div class="d-flex">
                <v-card-actions class="pa-0">
                  <v-btn color="t-grey" :disabled="saving" outlined @click="$emit('back')">
                    {{ 'Назад' }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    type="submit"
                    :loading="saving"
                    :disabled="service.onlyForRFResidents && !form.confirmRFResidents"
                  >
                    {{ 'Забронировать' }}{{ totalPrice ? ` за ${totalPrice} ₽` : '' }}
                  </v-btn>
                </v-card-actions>

                <div v-if="form.paymentType === PaymentType.WIRE_PAYMENT" class="ml-4 text-body-2">
                  <div class="primary--text">Информация для Юридических лиц.</div>
                  <div v-if="service.acceptGuaranteeLetter" class="text--secondary">
                    Для подтверждения и оплаты заказа с вами свяжется менеджер. Просим вас оплатить
                    заказ до начала бронирования или иметь при себе гарантийное письмо об оплате
                    заказа по прибытии.
                  </div>
                  <div v-else class="text--secondary">
                    Для подтверждения и оплаты заказа с вами свяжется менеджер. Просим вас оплатить
                    заказ до начала бронирования.
                  </div>
                </div>
              </div>

              <AcceptTerms :service="service" />
            </div>
          </template>

          <div
            v-else-if="searchResult && searchResult.length === 0"
            class="mt-6 font-weight-regular"
          >
            К сожалению, по вашему запросу ничего не найдено. Попробуйте изменить количество гостей
            или даты бронирования.
          </div>
        </v-form>
      </v-col>

      <v-col cols="12" md="4">
        <AsideInfo :service="service" :variant="selectedVariant">
          <template v-if="selectedTariff" #price>
            от {{ $n(selectedTariff.price, 'currency') }} /
            {{ PriceUnitLabel[selectedTariff.priceUnit] }}
          </template>
        </AsideInfo>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { actions, state } from '@/store/store';
import { PriceUnitLabel } from '@/constants/serviceTariff';
import { PaymentType } from '@/constants/payment';
import PhoneField from '@/components/ui/PhoneField.vue';
import BlockTitle from '@/components/ui/BlockTitle.vue';
import EmailField from '@/components/ui/EmailField.vue';
import PageHeader from '@/components/ui/PageHeader.vue';
import EditorContent from '@/components/ui/EditorContent.vue';
import AcceptTerms from '@/components/terms/AcceptTerms.vue';
import ExternalCompanyForm from '@/components/payer/ExternalCompanyForm.vue';
import HotelSearchForm from '@/components/hotel/form/HotelSearchForm.vue';
import HotelCommonForm from '@/components/hotel/form/HotelCommonForm.vue';
import AsideInfo from '@/components/tariff/AsideInfo.vue';

export default {
  name: 'HotelForm',

  components: {
    AsideInfo,
    HotelCommonForm,
    HotelSearchForm,
    ExternalCompanyForm,
    AcceptTerms,
    EditorContent,
    PageHeader,
    EmailField,
    BlockTitle,
    PhoneField,
  },

  refs: ['commonForm'],

  data: () => ({
    saving: false,
    searching: false,
    searchParams: null,
    searchResult: null,
    form: {
      tariffId: null,
      tariffModifierIds: [],
      expectedCheckin: null,
      expectedCheckout: null,
      name: null,
      phone: null,
      email: null,
      paymentType: PaymentType.ONLINE,
      confirmRFResidents: undefined,
    },
    externalCompanyForm: {
      name: null,
      inn: null,
      kpp: null,
      fio: null,
      position: null,
      phone: null,
      email: null,
    },
    PaymentType: PaymentType,
    PriceUnitLabel: PriceUnitLabel,
  }),

  computed: {
    service() {
      return state.selectedService;
    },

    subtitle() {
      return this.service.title;
    },

    paymentTypeItems() {
      return [
        { text: 'Банковская карта', value: PaymentType.ONLINE },
        { text: 'Безналичный расчет для Юридических лиц', value: PaymentType.WIRE_PAYMENT },
        { text: 'СБП', value: PaymentType.SBP },
      ];
    },

    selectedTariff() {
      return this.$refs.commonForm?.selectedTariff;
    },

    selectedVariant() {
      return this.$refs.commonForm?.selectedVariant;
    },

    totalPrice() {
      return this.$refs.commonForm?.totalPrice;
    },
  },

  created() {
    if (this.service.onlyForRFResidents) {
      this.form.confirmRFResidents = true;
    }
  },

  methods: {
    onSearchResult({ params, data }) {
      this.searchParams = params;
      this.searchResult = data;
    },

    async onSubmit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.saving = true;

      const payload = {
        serviceId: this.service.id,
        startAt: this.searchParams.periodStart,
        endAt: this.searchParams.periodEnd,
        guests: {
          adultsNumber: this.searchParams.adultsNumber,
          childsNumber: this.searchParams.childsNumber,
        },
        tariffId: this.form.tariffId,
        tariffModifierIds: this.form.tariffModifierIds,
        expectedCheckin: this.form.expectedCheckin,
        expectedCheckout: this.form.expectedCheckout,
        name: this.form.name,
        phone: this.form.phone,
        email: this.form.email,
        paymentType: this.form.paymentType,
      };

      if (payload.paymentType === PaymentType.WIRE_PAYMENT) {
        payload.externalPayer = this.externalCompanyForm;
      }

      try {
        const serviceTicket = await actions.createHotelOrder(payload);
        await actions.handlePayment({ serviceTicket });
      } catch (e) {
        alert('Произошла ошибка :( Попробуйте повторить позже!');
      } finally {
        this.saving = false;
      }
    },
  },
};
</script>
